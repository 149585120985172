<template>

<div class="toggle" :class="{'is-error': error}">

	<app-icon s="error" v-if="error" class="error" />
	
	<div class="toggle-text">{{ input.label }}</div>

	<app-icon s="check" :class="{'is-active': value}" class="toggle-icon" v-on:click.native="onToggleClick" />

</div>

</template>

<script>

export default {

	props: ['input', 'value', 'error'],

	methods: {

		onToggleClick: function() {

			this.$emit('input', !this.value)

		}

	}

}

</script>

<style scoped>

.toggle {
	margin-bottom: 20px;
	display: flex;
	padding-top: 4px;
}

.toggle-text {
	padding: 8px 20px;
	min-height: 36px;
	line-height: 20px;
	color: #1A234C;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.4px;
	width: 100%;
	flex-grow: 1;
}

.toggle-icon {
	color: #eee;
	font-size: 18px;
	margin-right: 15px;
	flex-shrink: 0;
	width: 36px;
	height: 36px;
	text-align: center;
	line-height: 32px;
	border-radius: 8px;
	cursor: pointer;
	border: 2px solid #ccc;
}

.toggle-icon:hover {
	border-color: #666;
	color: #666;
}

.toggle-icon.is-active {
	color: #fff;
	background-color: #1277D4;
	border-color: #1277D4;
}

</style>
